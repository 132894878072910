// React
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Style
import './Styles/styles.css'

// Fichier
import Header from './components/header/header'
import Home from './pages/Home/Home'
import Error from './pages/error/error'
import Footer from "./components/footer/footer";

/**
 * Renders the root component of the React application with the specified routes and components.
 * @param {{HTMLElement}} rootElement - The root element in the HTML document where the React application will be rendered.
 * @returns html element
 */
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/A_Propos" element={<About />} /> */}
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
)