function SectionOffre({ offre }) {

  return (
    <div className="main-offre" id={offre.id}>
      <div className="main-offre-containerTitle">
        <h2>{offre.title}</h2>
      </div>
      <div className="main-offre-containerParagraphe">
        <p className="main-offre-containerParagraphe-paragraphe">
          {offre.description1}
          <strong className="textStrong">{offre.numberBold}</strong>
          {offre.description2}
        </p>
        <ul className="main-offre-containerParagraphe-list">
            {offre.listes.map((values, keys) => {
              return <li key={`${keys}`}>{`${values}`}</li>
            })}
          
        </ul>
      </div>
      <div className="main-offre-img">
        <img src={offre.cover} alt={offre.alt} />
      </div>
    </div>
  );
}

export default SectionOffre;
