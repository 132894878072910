import { useEffect, useState } from "react";
import Banner from "../../components/banner/banner";
import Card from "../../components/card/Card";
import SectionOffre from "../../components/sectionOffre/sectionOffre";
import Amenagement from "../../components/amenagement/Amenagement";

function Home() {
  const [cards, setCards] = useState([{listes: []}]);
  const [offres, setOffres] = useState([{ listes: [] }]);
  const [amenagements, setAmenagements] = useState([])
  useEffect(() => {
    async function createCards() {
      let result = await fetch("./data.json");
      let data = undefined;
      if (result.status === 200) {
        data = await result.json();
      } else {
        return null;
      }

      setCards(data.dataMainCard);
      setOffres(data.dataMainOffre);
      setAmenagements(data.covers);
    }
    createCards();
  }, []);


  return (
    <div className="main">
      <Banner key="banner"/>
      <div className="main-card">
        {cards.map((values, keys) => (
          <Card key={`${keys}`} card={values} />
        ))}
        {offres.map((values, keys) => (
          <SectionOffre key={`${keys}`} offre={values} />
        ))}
        <Amenagement amenagement={amenagements}/>
      </div>
    </div>
  );
}
export default Home;
