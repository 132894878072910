
function Card({ card }) {

  
  return (
    <div className="card" id={card.id}>
      <div className="card-img">
        <img src={card.cover} alt={card.alt} />
      </div>
      <div className="card-descriptions">
        <h2 className="card-descriptions-title">{card.title}</h2>
        <p className="card-descriptions-description">
          {card.description1}
          <strong className="textStrong">{card.numberBold}</strong>
          {card.description2}
        </p>
        <ul>
          <li>{card.listes}</li>
        </ul>
      </div>
    </div>
  );
}

export default Card;
