import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [image, setimage] = useState({});

  useEffect(() => {
    async function getImage() {
      let result = await fetch("./data.json");
      let data = undefined;
      if (result.status === 200) {
        data = await result.json();
      } else {
        return null;
      }

      setimage(data.dataHeader);
    }
    getImage();
  }, []);

  return (
    <nav className="header-container">
      <div className="header-container-navLogo">
        <Link to="/">
          <div className="header-container-navLogo-logo">
            <img src={image.image} alt="Logo Bouger +" />
          </div>
        </Link>
        <div className="header-container-navLogo-slogan">
          <p>Partenaire de votre bien-être en entreprise !</p>
        </div>
      </div>
      <div className="header-container-nav">
        <Link className="header-container-nav-accueil" to="/">
          Accueil
        </Link>
        <Link className="header-container-nav-aPropos" to="/A_propos">
          À propos
        </Link>
      </div>
    </nav>
  );
}

export default Header;
