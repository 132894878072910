import { Link } from 'react-router-dom'

function Amenagement({amenagement}) {
    return (
      <>
        <div className="container-amenagement">
           <img src={amenagement} alt='aménagement'/>
        </div>
        <div className="contact">
         <div className="contact-title">
            <h2>Nous Contacter</h2>
         </div>
         <div className="contact-link">
            <Link to="mailto:lacoste.sokhna@bougerplus.fr" className="contact-link-mail">lacoste.sokhna@bougerplus.fr</Link>
         </div>
        </div>
      </>
     );
}

export default Amenagement;