import banner from "../../assets/bannière bouger plus.webp";

function Banner(props) {
  return (
    <div className="banner">
      <div className="banner-container">
        <img src={banner} alt="bannière" className="banner-container-img" />
      </div>
    </div>
  );
}

export default Banner;
